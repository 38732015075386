import React, { useEffect, useState  } from 'react';
import './fullscreen-viewer.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PlaceIcon from '@material-ui/icons/Place';
import ReactGA from "react-ga";

const FullscreenViewer = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const arrowClick = (event, state) => {
        event.stopPropagation();
        ReactGA.event({
            category: 'photo_click',
            action: state===0 ? 'previous_picture' : 'next_picture',
        });
        props.changePhotoCallback(state);
    }
    let img;
    const leftArrow = (
        <div className={"back-arrow arrow"} onClick={(event) => arrowClick(event, 0)} data-state={0} title={"Back"}>
            <NavigateBeforeIcon className={"arrow-icon"} />
        </div>
    )

    const rightArrow = (
        <div className={"forward-arrow arrow"} onClick={(event) => arrowClick(event,1)} title={"Next"}>
            <NavigateNextIcon className={"arrow-icon"} />
        </div>
    )

    const loadingView = (
        <div className={"loading-view"}>
            <CircularProgress />
        </div>
    )

    const photoTitle = props.photoTitle;

    const imageComponent = (
        <>
            <img src={props.currentImage.src} alt={props.photoTitle} onClick={(event) => arrowClick(event, 1)} />
            {photoTitle !== "" ? (
                <div className={"info-box"}>
                    <PlaceIcon /><span>{photoTitle}</span>
                </div>
            ): undefined}

        </>
    )

    useEffect( () =>{
        // eslint-disable-next-line
        img = new Image();
        img.onload = () => {
            setIsLoaded(true);
        }
        img.src = props.currentImage.src;
        img.onclick = ((event) => arrowClick(event, 1));
    }, []);

    const quitFullScreen = () => {
        ReactGA.event({
            category: 'photo_click',
            action: 'exit_fullscreen',
            label: props.currentImage.src
        });
        props.quitFullscreen();
    }


    return (
        <>
            <div className={"fullscreen-viewer"} onClick={quitFullScreen}>
                {props.currentImage.dataset.index  <= 0 ? null: leftArrow }
                <div id={"fullscreen-viewer"}>
                    {isLoaded? imageComponent: loadingView}
                </div>
                {props.currentImage.dataset.index  >= (props.listLenght - 1) ? null: rightArrow }
            </div>
        </>
    )
}

export default FullscreenViewer;
