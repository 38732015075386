import React, {useState} from 'react';
import './home.scss'
import PhotoGrid from "../../components/photo-grid/photo-grid";
import Footer from "../../components/footer/footer";
import ReactFitText from "react-fittext";
import Rellax from "react-rellax";


const Home = () => {

    const [isPhotoLoaded, setIsPhotoLoaded] = useState(false);

    return (
        <div className={"main-container"}>
            <div className={"first-container"}>
                <div className={"background"}>
                </div>
                <div className={"bottom"}>

                </div>
                <ReactFitText><h1>Jean-Philippe Baillargeon</h1></ReactFitText>
            </div>
            <div className={"video-container"}>
                <Rellax speed={8} className={"text"}>
                    <ReactFitText><h2>SHOWREEL 2019</h2></ReactFitText>
                </Rellax>
                <div className={"content"}>
                    <div className="videoWrapper">
                        <iframe width="560" height="315" title={"showreel"} src="https://www.youtube.com/embed/qM0HgCdzqOE" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <div className={"container"}>
                <PhotoGrid photoListUrl={process.env.REACT_APP_PHOTO_URL}
                           photoIsLoadedCallback={() => setIsPhotoLoaded(true)}/>
            </div>
            {isPhotoLoaded ? <Footer/> : null}
        </div>
    )
};

export default Home
