import React from "react";
import instagramLogo from "../../media/home/logos/instagram.svg";
import youtubeLogo from "../../media/home/logos/youtube.svg";
import githubLogo from "../../media/home/logos/github.svg";
import './footer.scss'
import ReactGA from "react-ga";

const Footer = () => {
    const socialClick =(type) => {
        ReactGA.event({
            category: 'social_click',
            action: type,
        });
    }
    return (
        <div className={"logos"}>
            <ul>
                <li><a href={"http://instagram.com/jeanphilippebaillargeon"} onClick={() => socialClick('Instagram')}><img className={"icone"} src={instagramLogo} alt={"instagram-logo"}/></a>
                </li>
                <li><a href={"http://youtube.com/jpbaillargeon"} onClick={() => socialClick('YouTube')}><img className={"icone"} src={youtubeLogo} alt={"instagram-logo"}/></a></li>
                <li><a href={"http://github.com/jpbaillargeon"} onClick={() => socialClick('GitHub')}><img className={"icone"} src={githubLogo} alt={"GitHub-logo"}/></a></li>
            </ul>
        </div>
    )
};

export default Footer;
