import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Hind',
            'sans-serif'
        ].join(','),
        h3: {
            fontWeight: 500,
        }
    },
});

export default theme