import React from 'react'
import './photo-grid.scss';
import {withFirebase} from "../Firebase";
import EXIF from "exif-js/exif";
import FullscreenViewer from "../fullscreen-viewer/fullscreen-viewer";
import {Snackbar} from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import Typography from "@material-ui/core/Typography";
import ReactGA from 'react-ga';

class PhotoGridBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photoList: null,
            loadedPhotos: null,
            fullscreenViewer: false,
            fullscreenPicture: null,
            snackBarOpen: false,
            cameraInfo: {
                make: '',
                model: '',
                shutterSpeed: '',
                aperture: '',
                focal: '',
                lensMake: '',
                iso: '',
            }
        };

        this.putPhotos = this.putPhotos.bind(this);
        this.arrangeRow = this.arrangeRow.bind(this);
        this.photoClick = this.photoClick.bind(this);
        this.changeToImage = this.changeToImage.bind(this);
        this.changePhotoCallback = this.changePhotoCallback.bind(this);

        this.loadedPhotos = [];
    }

    loadedPhotos;
    imgMargin = 5;
    imgMinHeight = 400;
    numSteps = 20.0;
    imgObserver = null;

    componentDidMount() {
        document.onkeydown = (event) => {
            switch (event.keyCode) {
                case 27:
                    if(this.state.fullscreenViewer) {
                        this.setState({
                            fullscreenViewer: false
                        })
                    }
                    break;
                case 37:
                    if(this.state.fullscreenViewer) {
                        this.changePhotoCallback(0)
                    }
                    break;
                case 39:
                    if(this.state.fullscreenViewer) {
                        this.changePhotoCallback(1)
                    }
                    break;
                default:
                    break;
            }
        };
        window.addEventListener('resize', this.putPhotos)
        let that = this;
        let observerCreatedPromise = new Promise((resolve, reject) => {
            window.addEventListener("load", function(event) {
                that.createObserver();
                resolve()
            }, false)
        });
        window.addEventListener("scroll", () => {
            this.setState({snackBarOpen: false});
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                ReactGA.event({
                    category: 'scroll',
                    action: 'User has to end'
                });
            }
        });
        Promise.all([this.loadPhotoList, observerCreatedPromise]).then((values) => {
            this.arrangeRow();
        })
    }

    loadPhotoList = new Promise((resolve, reject) => {
        fetch(this.props.photoListUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        photoList: result
                    })
                    resolve()
                }
            )
    })

    createObserver = () => {
        let options = {
            root: null,
            rootMargin: 2 * this.imgMinHeight + 'px',
            threshold: this.buildThresholdList()
        };

        this.imgObserver = new IntersectionObserver(this.handleIntersect, options);
    }

     handleIntersect = (entries, observer) => {
        entries.forEach((entry, index) => {
            if(entry.isIntersecting) {
                if(entry.target.dataset.src !== 'null') {
                    entry.target.onload = () => {
                        entry.target.className = "portfolio-photo";
                    }
                    entry.target.src = entry.target.dataset.src;
                    entry.target.dataset.src = null;
                }
            }
        });
    }


    buildThresholdList = () => {
        let thresholds = [];

        for (let i=1.0; i<=this.numSteps; i++) {
            let ratio = i/this.numSteps;
            thresholds.push(ratio);
        }

        thresholds.push(0);
        return thresholds;
    }

    arrangeRow = () => {
        let i = 0;
        this.state.photoList.forEach((photo, index) => {
            let img = new Image();
            img.dataset.url = photo.url;
            img.onload = () => {
                i++;
                if(i === this.state.photoList.length) {
                    this.props.photoIsLoadedCallback();
                    this.putPhotos();
                }
            }
            if(process.env.NODE_ENV === 'production') {
                this.props.firebase.getUrl('images/portfolio/' + photo.url).then( (url) => {
                    img.dataset.src = url;
                });
                this.props.firebase.getUrl('images/portfolio/lowrez/' + photo.url).then( (url) => {
                    img.src = url;
                });
            } else {
                img.dataset.src = '/images/portfolio/web/' + photo.url;
                img.src = '/images/portfolio/web' + photo.url;
            }

            img.alt = photo.location;
            img.dataset.index = index;
            img.className = "portfolio-photo lazy";
            img.addEventListener("click", this.photoClick, false);
            this.imgObserver.observe(img);
            this.loadedPhotos.push(img);
        })
    }

    photoClick(event) {
        ReactGA.event({
            category: 'photo_click',
            action: 'fullscreen_view',
            label: event.target.dataset.url
        });
        if (event.target.dataset.src !== 'null') {
            event.target.src = event.target.dataset.src;
            event.target.dataset.src = null;
            event.target.className = "portfolio-photo";
        }
        this.getExifData(event.target);
        this.setState({
            fullscreenPicture: event.target,
            fullscreenViewer: !this.state.fullscreenViewer,
        })
    }

    getExifData(image) {
        let that = this;
        EXIF.getData(image, function() {
            const make = EXIF.getTag(this, "Make");
            let model = EXIF.getTag(this, "Model");
            let shutterSpeed = EXIF.getTag(this, "ExposureTime") ? EXIF.getTag(this, "ExposureTime"): '';
            const aperture = EXIF.getTag(this, "FNumber");
            const focal = EXIF.getTag(this, "FocalLength");
            const lensMake = EXIF.getTag(this, 'undefined');
            const iso = EXIF.getTag(this, "ISOSpeedRatings");

            if(make === 'DJI') {
                model = 'Mavic Air';
            }


            const cameraInfo = {
                make: make,
                model: model,
                shutterSpeed: shutterSpeed,
                aperture: aperture,
                focal: focal,
                lensMake: lensMake,
                iso: iso,
            }
            that.saveCameraInfo(cameraInfo);
        })
    }

    saveCameraInfo = (cameraInfo) => {
        this.setState({
            cameraInfo: cameraInfo
        })
    }

     putPhotos() {
        document.getElementById('photo-grid').innerHTML = '';
        let actualWidth = 0;
        let row = document.createElement('div');
        row.className = 'row';
        const divWidth = document.getElementById('photo-grid').clientWidth;

        this.loadedPhotos.forEach((photo) => {
            const tempWidth = actualWidth;
            actualWidth += (this.imgMinHeight/photo.height) * photo.width;
            if((actualWidth + ((row.children.length - 1) * this.imgMargin) >= divWidth)) {
                const actualHeight = ((divWidth- (row.children.length -1) * this.imgMargin)) / (tempWidth) * this.imgMinHeight;
                row.style.height = actualHeight + 'px';
                document.getElementById('photo-grid').appendChild(row);
                row = document.createElement('div');
                row.className = 'row';
                row.appendChild(photo);
                actualWidth = (this.imgMinHeight/photo.height) * photo.width;

            } else {
                row.appendChild(photo);
            }
        })
        row.style.height = this.imgMinHeight + 'px';
        row.className = 'row last-class';
        document.getElementById('photo-grid').appendChild(row);
         setTimeout(() => {
             if(window.pageYOffset === 0) {
                 this.setState({snackBarOpen: true});
             }
         }, 15000);
    }

    changeToImage(index) {
        const newImage = this.loadedPhotos[index];
        if (newImage.dataset.src !== 'null') {
            newImage.src = newImage.dataset.src;
            newImage.dataset.src = null;
            newImage.className = "portfolio-photo";
        }
        this.getExifData(newImage)
        this.setState({
            fullscreenPicture: newImage,
        })
    }

    changePhotoCallback = (state) => {
        if(state === 0 ) {
            if((this.state.fullscreenPicture.dataset.index) - 1 >= 0) {
                this.changeToImage(parseInt(this.state.fullscreenPicture.dataset.index) - 1);
            }
        } else if (state === 1 ) {
            if (this.state.fullscreenPicture.dataset.index < this.loadedPhotos.length - 1) {
                this.changeToImage(parseInt(this.state.fullscreenPicture.dataset.index) + 1);
            }
        }
    }

    transition = (props) => {
        return <Slide {...props} direction="up" />;
    }




    render() {
        return (
            <>
                <Typography variant={'h3'} className={"portfolio-title"}>My Portfolio</Typography>
                <div className={"photo-grid"} id={"photo-grid"}></div>
                {this.state.fullscreenViewer? <FullscreenViewer listLenght={this.state.photoList.length} changePhotoCallback={this.changePhotoCallback} quitFullscreen={() => this.setState({fullscreenViewer: false})} currentImage={this.state.fullscreenPicture} photoTitle={this.state.fullscreenPicture.alt}/>: null}
                <Snackbar
                    open={this.state.snackBarOpen}
                    onClose={() => {this.setState({snackBarOpen: false})}}
                    TransitionComponent={this.transition}
                    message={"Scroll a little bit to see my portfolio 🙃"}
                    autoHideDuration={5000}
                />
            </>
        )
    }
}

const PhotoGrid = withFirebase(PhotoGridBase);

export default PhotoGrid;
