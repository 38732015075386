import React from 'react';
import './App.css';
import Home from "./containers/home/home";

import ReactGA from 'react-ga';
import theme from "./theme.css";

import { ThemeProvider } from '@material-ui/core/styles';


function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
      <ThemeProvider theme={theme}>
        <Home/>
      </ThemeProvider>
  );
}

export default App;
